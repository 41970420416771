import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let currentIndex = 0;
const listMainSections = document.getElementsByClassName("listMain");

function slideSections() {
    currentIndex = (currentIndex + 1) % listMainSections.length;
    const currentSection = listMainSections[currentIndex];

    // Slide the current section from left to right
    currentSection.style.left = "-300px";

    // Insert the next section after the current section
    const nextIndex = (currentIndex + 1) % listMainSections.length;
    const nextSection = listMainSections[nextIndex];
    nextSection.style.left = "300px";

    // Animate the sliding effect
    animate(currentSection, { left: "300px" }, 500, function () {
        currentSection.style.left = "0";
        nextSection.style.left = "0";
    });
}

// Simple animation function
function animate(element, properties, duration, callback) {
    const start = performance.now();

    function step(timestamp) {
        const time = timestamp - start;

        Object.keys(properties).forEach(function (property) {
            const initialValue = parseFloat(getComputedStyle(element)[property]);
            const targetValue = parseFloat(properties[property]);
            const delta = (targetValue - initialValue) * (time / duration);
            element.style[property] = initialValue + delta + "px";
        });

        if (time < duration) {
            requestAnimationFrame(step);
        } else {
            callback();
        }
    }

    requestAnimationFrame(step);
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="about">
                    <div class="storyCon">
                        <div class="conT">ABOUT</div>
                        <p>Discover a world where music and art converge in a unique way. Our collection features 3D characters, each embodying the spirit of a guitarist with over 120 distinctive traits. When you own one of our NFTs, you own a piece of music and art history, securely stored on the blockchain. Join our community of music and art enthusiasts who share your passion.</p>
                        <p>The "Guitarist" NFT Collection is limited, making each NFT a rare gem. Don't miss the opportunity to own something extraordinary. Whether you're a collector, musician, or artist, you're invited to be part of our community. Dive into the "Guitarist" NFT Collection and join the movement that's reshaping how we experience music, art, and NFTs.</p>
                    </div>

                </div>
            </div>
        )
    }
}

export default Story;

