import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import normal1 from '../assets/normal1.jpg';
import normal2 from '../assets/normal2.jpg';
import normal3 from '../assets/normal3.jpg';
import normal4 from '../assets/normal4.jpg';
import normal5 from '../assets/normal5.jpg';
import normal6 from '../assets/normal6.jpg';
import normal7 from '../assets/normal7.jpg';
import normal8 from '../assets/normal8.jpg';
import normal9 from '../assets/normal9.jpg';
import normal10 from '../assets/normal10.jpg';

import rare1 from '../assets/black metal paint.jpg';
import rare2 from '../assets/black star paint.jpg';
import rare3 from '../assets/claws eyes paint.jpg';
import rare4 from '../assets/goldface.jpg';
import rare5 from '../assets/jester.jpg';
import rare6 from '../assets/joker.jpg';
import rare7 from '../assets/leatherface.jpg';
import rare8 from '../assets/monster.jpg';
import rare9 from '../assets/predator.jpg';
import rare10 from '../assets/skull.jpg';
import rare11 from '../assets/tengu.jpg';

import superRare1 from '../assets/ALIEN.jpg';
import superRare2 from '../assets/BELPHEGOR.jpg';
import superRare3 from '../assets/EVIL CLOWN.jpg';
import superRare4 from '../assets/EVIL WITCH.jpg';
import superRare5 from '../assets/JASON.jpg';
import superRare6 from '../assets/LUCIFER.jpg';
import superRare7 from '../assets/PINHEAD.jpg';
import superRare8 from '../assets/SAMAEL.jpg';
import superRare9 from '../assets/WEREWOLF.jpg';
import superRare10 from '../assets/ZOMBIE.jpg';

import stickyimg from '../assets/pink-headphones-wireless-digital-device-removebg-preview.png';

const Stry = () => {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, [])
}
class NftTypes extends Component {

  state = {
    _imageOpen: 0
  }

  imageOpen = async event => {
    event.preventDefault();

    this.setState({ _imageOpen: 1 });
  }

  render() {
    return (

      <div class="boxWrapNFT">

        <div class="about2-2">
          <div class="storyCon2">
            <div class="conT2">TYPES OF NFT</div>           
         
            <div class="type">
   
            <div class="details">
              <p><span class="detailsS">- There is a max supply of 10’000 NFTs.</span></p>
              <p><span class="detailsS">- There are 9000 normal NFTs + 750 rare NFTs + 250 super rare NFTs.</span></p>
              <p><span class="detailsS">- Normal & rare NFTs can be minted from the website and traded on open markets (Rare NFTs are distributed randomly among normal NFTs)</span></p>
              <p><span class="detailsS">- Super rare NFTs will be sold separately in future.</span></p>
            </div>
            <p></p>
              <details data-aos="fade-up" data-aos-duration="1000">
                <summary><span class="typeH">NORMAL NFT</span></summary>
                <p></p>

                <div class="bgC">
                 <center><div class="typeCon">A total of 9000 unique NFTs, each characterized by various traits. Additionally, there are 14 distinct electric guitars featured throughout the collection. These traits and guitars combine to make each NFT a one-of-a-kind masterpiece that reflects the diverse world of music and art.</div></center>

                  <div class="imagesNormal">
                    <div class="imagesNormalDiv">
                      <img src={normal1} />
                      <img src={normal2} />
                      <img src={normal3} />
                      <img src={normal4} />
                      <img src={normal5} />
                    </div>
                    <div class="imagesNormalDiv">
                      <img src={normal6} />
                      <img src={normal7} />
                      <img src={normal8} />
                      <img src={normal9} />
                      <img src={normal10} />
                    </div></div>

                </div>
              </details>

              <p></p>

              <details data-aos="fade-up" data-aos-duration="1000">
                <summary><span class="typeH">RARE NFT</span></summary>
                <p></p>

                <div class="bgC">
                <center><div class="typeCon">Among the 750 rare NFTs, you'll find 11 distinct masks/face paint designs, adding a touch of mystique and individuality to each character. To make these rare NFTs even more special, they share exclusive traits of 10 unique electric guitars with the super rare NFTs. These guitars are designed to complement the character and style of each guitarist, making them an integral part of their story.</div></center>

                  <div class="imagesNormal">
                    <div class="imagesNormalDiv">

                      <div>
                        <img src={rare1} />
                        <div class="imgH">Black Metal Paint</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare2} />
                        <div class="imgH">Black Star Paint</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare3} />
                        <div class="imgH">Claws Eyes Paint</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare4} />
                        <div class="imgH">Goldface</div>
                        <p></p>
                      </div>

                      <div>
                        <img src={rare5} />
                        <div class="imgH">Jester</div>
                        <p></p>

                      </div>
                    </div>
                    <div class="imagesNormalDiv">
                      <div>
                        <img src={rare6} />
                        <div class="imgH">Joker</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare7} />
                        <div class="imgH">Leatherface</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare8} />
                        <div class="imgH">Monster</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare9} />
                        <div class="imgH">Predator</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare10} />
                        <div class="imgH">Skull</div>
                        <p></p>

                      </div>

                      <div>
                        <img src={rare11} />
                        <div class="imgH">Tengu</div>
                        <p></p>

                      </div>
                    </div></div>

                </div>
              </details>

              <p></p>
              <details data-aos="fade-up" data-aos-duration="1000">
                <summary><span class="typeH">Super Rare NFT</span></summary>
                <p></p>
                <div class="bgC">
              <center><div class="typeCon">Among the 250 super rare NFTs, you'll find characters adorned with special evil faces, adding an aura of mystique and intrigue to their personalities, in addition to the 10 unique exclusive electric guitars.</div></center>
                <div class="imagesNormal">
                  <div class="imagesNormalDiv">

                    <div>
                      <img src={superRare1} />
                      <div class="imgH">ALIEN</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare2} />
                      <div class="imgH">BELPHEGOR</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare3} />
                      <div class="imgH">EVIL CLOWN</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare4} />
                      <div class="imgH">EVIL WITCH</div>
                      <p></p>
                    </div>

                    <div>
                      <img src={superRare5} />
                      <div class="imgH">JASON</div>
                      <p></p>

                    </div>
                  </div>
                  <div class="imagesNormalDiv">
                    <div>
                      <img src={superRare6} />
                      <div class="imgH">LUCIFER</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare7} />
                      <div class="imgH">PINHEAD</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare8} />
                      <div class="imgH">SAMAEL</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare9} />
                      <div class="imgH">WEREWOLF</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={superRare10} />
                      <div class="imgH">ZOMBIE</div>
                      <p></p>

                    </div>

                  </div></div>

                </div>
              </details>
              <p></p>
            </div>

          </div>

        </div>

        <img class="stickyimg" src={stickyimg}/>
      </div>
    )
  }
}

export default NftTypes;

