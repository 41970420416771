import './App.css';
import React, { Component } from 'react';
import Story from "./components/story";
import Ticker from './components/ticker';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from './assets/logo.png';
import logo2 from './assets/logo.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import NftTypes from './components/nftTypes';
import Footer from './components/footer';
import Team from './components/team';
import $ from "jquery";
import guitar from './assets/guitar--.png';
import rock from './assets/rockhand.png';
import Guitar from './components/guitar';
import audio from './assets/website music.mp3';
import musicOn from './assets/rock-and-roll.png';
import musicOff from './assets/rock-and-roll-off.png';
import mm from './assets/MetaMask_Fox.svg.png';
import wc from './assets/wc.png';
import video from './assets/My Video.mp4';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let usdtAmount = 10000000;
let selectedNFTIMG = './assets/logo.png';
let sectionCount = '';
let song;
let song1;
let soundfinder1;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 0);
});

const osL = () => {
	window.open("https://opensea.io/collection/guitarist-2");
}

const linkCon = () => {
	window.open("https://etherscan.io/address/0xa249d8f280ba32ab3e95bddf4f3dbde059d62ac3");
}

const WP = () => {
	window.open("https://guitaristnft.com/guitaristnftwhitepaper.pdf");
}

const cs = () => {
    window.open("https://www.coinscope.co/coin/guitarist");
}

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "partner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "partnerMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_treasuryWallet",
				"type": "address"
			}
		],
		"name": "setTreasuryWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet2",
				"type": "address"
			}
		],
		"name": "setWallet2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet3",
				"type": "address"
			}
		],
		"name": "setWallet3",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "treasuryWallet",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wallet2",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wallet3",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

const address = "0xA249d8F280BA32Ab3E95bDdf4F3DBDE059d62AC3";

let contract;
let token_contract;
let _selectedNFT;

class App extends Component {

	state = {
		_imageSrc: "",
		walletAddress: "",
		totalSupply: "",
		totalSupplyChanging: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		_select: 0,
		pressBtnName: "Select NFT",
		_approved: '',
		_selectedNFT: '',
		_selectedNFTID: '',
		_selectedNFTURL: '',
		_totalSupplyVar: '12000',
		_sectionCount: '',
		sound: 0,
		_ser: 0
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {


		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					//const signer = (new Web3Provider(window.ethereum).getSigner)();

					contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 1;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.infura.io/v3/'],

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//...........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 10000) {

										onlyLeft = 10000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///.................................///



								//...........................................................................//

							}
							catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 1;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.infura.io/v3/'],

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//...........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 10000) {

										onlyLeft = 10000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///.................................///



								//...........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}
						//})();

						//......................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {
			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });


					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					rpc: {
						1: "https://magical-falling-pond.discover.quiknode.pro/b78e2be20e0f4254298b96fa6e242c369a71ae86/" // replace this with QuickNode URL
					}
				}
			}
		};


		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });
			//const signer = (new Web3Provider(window.ethereum).getSigner)();

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);
			//const provider = new Provider('https://testnet.era.zksync.dev');
			// Note that we still need to get the Metamask signer
			/*const signer = (new Web3Provider(window.ethereum).getSigner)();
			contractZksync = new web3.eth.Contract(
				ABI,
				address,
				signer
			);
			this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
			console.log("provider : " + provider);
			console.log("signer : " + signer);
			console.log("contractZksync : " + contractZksync);*/

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//...........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///.................................///



							//...........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///.................................///



							//...........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//......................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	mm = async event => {
		event.preventDefault();

		//const userAgent = window.navigator.userAgent;
		//const isMobile = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Mobile|webOS|Opera Mini|IEMobile|Mobile Safari|SamsungBrowser|Nokia/.test(userAgent);
		//const isMetaMaskBrowser = window.ethereum && window.ethereum.isMetaMask;

		//if (isMobile && isMetaMaskBrowser) {

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				const contract = new web3mm.eth.Contract(ABI, address);

				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...

				const chainId = 1;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {

							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

										/*chainName: 'Base Goerli',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://goerli.base.org'],*/

										chainName: 'Ethereum Mainnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://mainnet.infura.io/v3/'],

									},
								],
							});
						}
					}

				}

				try {

					try {
						localStorage.setItem('isWalletConnected', true);
					} catch (ex) {
						console.log(ex)
					}

					totalSupplyNFT = await contract.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					publicSale = await contract.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					public_sale_cost = await contract.methods.publicSaleCost().call();
					this.setState({ _public_sale_cost: public_sale_cost });
					console.log("public_sale_cost :" + public_sale_cost);
					this.setState({ _cost: public_sale_cost });
					this.setState({ _mintStatus: false });

					MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
					this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
					console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

					public_mint_status = await contract.methods.public_mint_status().call();
					this.setState({ _public_mint_status: public_mint_status });
					console.log("public_mint_status :" + public_mint_status);

					owner = await contract.methods.owner().call();
					console.log("Owner" + owner);
					//...........................................................................//
					if (owner == account) {
						console.log("owner : " + owner)
						onlyLeft = 10000 - totalSupplyNFT;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}

						valueOfNFTs = mintAmount * 0;
						wMintAmount = mintAmount;


						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					} else {
						mintAmount = 1;

						if (totalSupplyNFT == 10000) {

							onlyLeft = 10000 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							valueOfNFTs = mintAmount * this.state._cost;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						}
					}
					///.................................///



					//...........................................................................//

				}
				catch (err) {
					console.log("err: " + err);

				}

			} catch (error) {
				console.error(error);
				// Handle errors or user rejections
			}

		} else {
			window.open("https://metamask.app.link/dapp/guitaristnft.com/");
		}
	};

	walletDisconnect = async event => {
		event.preventDefault();

		const userAgent = window.navigator.userAgent;

		const isMobile = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Mobile|webOS|Opera Mini|IEMobile|Mobile Safari|SamsungBrowser|Nokia/.test(userAgent);
		const isMetaMaskBrowser = window.ethereum && window.ethereum.isMetaMask;

		if (isMobile && isMetaMaskBrowser) {
			await window.ethereum.close();
		} else {


			// If not in the MetaMask browser, use Web3Modal for disconnection
			const web3Modal = new Web3Modal({
				network: "mainnet",
				cacheProvider: true,
				providerOptions: {
					walletconnect: {
						package: WalletConnectProvider,
						options: {
							infuraId: "bf933c3446b3464c988114813a1360ac"
						}
					}
				}
			});

			// Clear cached provider to disconnect
			web3Modal.clearCachedProvider();

			// Reload the page to reset the DApp state
			window.location.reload(true);

			try {
				localStorage.setItem('isWalletConnected', false);
			} catch (ex) {
				console.error(ex);
			}

		}


	}


	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	ser = async event => {
		event.preventDefault();
		if (song != null) {
			song.pause();
		}
		song1 = new Audio(audio);
		song1.play();

		this.setState({ _ser: 1 });
	}

	serClose = async event => {
		event.preventDefault();

		this.setState({ _ser: 0 });
		soundfinder1 = 0;
		this.setState({ sound: soundfinder1 });
		song1.pause();


		if (song != null) {
			song.play();
		}
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="cont">

						<Element name="header">
							{this.state._navbarOpen < 1 ?
								(<div class="miniBarMain">
									<div ><img class="logo" src={logo2} /></div>

									<div class="connect2">
										<div class="connect2btns">
											<form class="connect2-2-Mob" onClick={this.walletConnect}>

												<img class="mm" onClick={this.mm} src={mm} />

											</form>

											{this.state.walletAddress === '' ?
												(<form onClick={this.walletConnect} class="connect2-2-Mob">
													<img onClick={this.walletConnect} class="mm" src={wc} />
												</form>) : (<form onSubmit={this.walletDisconnect}>
													<button class="connect2-2-Mob-2">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

													</button>
												</form>)}
										</div>
									</div>

									<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
								</div>) :
								(<div class="miniBarMain">
									<div ><img class="logo" src={logo} /></div>

									<div class="connect2">
										<div class="connect2btns">
											<form class="connect2-2-Mob" onClick={this.walletConnect}>

												<img class="mm" onClick={this.mm} src={mm} />

											</form>

											{this.state.walletAddress === '' ?
												(<form onClick={this.walletConnect} class="connect2-2-Mob">
													<img onClick={this.walletConnect} class="mm" src={wc} />
												</form>) : (<form onSubmit={this.walletDisconnect}>
													<button class="connect2-2-Mob-2">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

													</button>
												</form>)}
										</div>
									</div>

									<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
								</div>)}

							<div class="headers">

								<div class="h1">

									<div class="logoDiv"><img class="logo" src={logo2} /></div>

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="types" spy={true} smooth={true} duration={550}>TYPES OF NFT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="guitars" spy={true} smooth={true} duration={550}>GUITARS</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINTING</Link></div>
										<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
										<div id="fontSize" onClick={WP}>WHITEPAPER</div>
										<div id="fontSize" onClick={cs}>AUDIT</div>

									</div>

									<div class="right">

										{this.state.walletAddress === '' ?
											(<form class="connect2">
												<button onClick={this.walletConnect} class="connectBtn" >CONNECT</button>
											</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>

								</div>

							</div>

							{this.state._navbarOpen > 0 ?
								(<div class="littleNav">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="types" spy={true} smooth={true} duration={550}>TYPES OF NFT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINTING</Link></div>
									<div ><Link activeClass="" id="fontSize" to="guitars" spy={true} smooth={true} duration={550}>GUITARS</Link></div>
									<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
									<div id="fontSize" onClick={WP}>WHITEPAPER</div>
									<div id="fontSize" onClick={cs}>AUDIT</div>

								</div>) : null}
						</Element>

						<div class="introduction">
							<div class="introduction2">
								<div class="in1">Where Music and Art Collide in Every NFT</div>
								<div class="in2">Dive into the "Guitarist" NFT Collection and join the movement that's reshaping how we experience music, art, and NFTs</div>
								<Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}><button class="button-85" role="button">EXPLORE</button> </Link>
							</div>
							<img class="mainImg" src={logo} />
						</div>

					</div>

					<Element name="story">
						<Story />
					</Element>

					<Element name="types">
						<NftTypes />
					</Element>

					<Element name="guitars">
						<Guitar />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">

							<div class="conT2">MINTING</div>
							<div class="mintDiv">
								<div id="content"><video class="selectedImg2" src={video} autoPlay loop playsInline muted></video></div>

								<div class="mintCon">
									<div class="totalSupply">{this.state.totalSupply} / 10000</div>
									{this.state._MAX_PER_WALLET == this.state.myNFTWallet ?

										(<div class="price"><div>Limit Reached!</div></div>) :
										(<div class="price"><div onClick={osL}>TRADE ON OPENSEA</div></div>)}
									<p class="price" onClick={linkCon}>0xA24...2AC3</p>

									<div class="priceBox">
										<div class="prices">Price: 0.05 ETH</div>
										<p></p>
										<div class="prices"><span class="quotes">- Immutable project metadata and images -</span></div>

										<div class="prices"><span class="quotes">- Unchangeable project data secures buyer's confidence -</span></div>
									</div>

									<div class="minting_count_button">
										<div class="center">
											<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>

										<div class="center">
											<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
										</div>
									</div>

									{this.state.walletAddress === '' ?

										(<div class="mintbuttondiv">
											<button onClick={this.walletConnect} class="btnfos-0-3">Connect</button>
											<Link activeClass="" id="fontSize" to="header" spy={true} smooth={true} duration={550}><button class="btnfos-0-3-mob">Connect</button></Link>
										</div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3" type="submit">
															Mint Here</button>
														<button class="btnfos-0-3-mob" type="submit">
															Mint Here</button>
													</form>

												</div>
											) : (

												<div>
													<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3" type="submit">Mint Here</button>
														<button class="btnfos-0-3-mob" type="submit">Mint Here</button>
													</form>
												</div>

											)}
										</div>)}
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your NFT</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div class="success-message">
												<h2>Successful NFT Minting!</h2>
												<p>Your NFT has been successfully minted.</p>
											</div>)
											: null}

									</div>

								</div>

							</div>

							<img class="rock" src={rock}></img>

						</div>

					</Element>

					<Element name="team">
						<Team />
					</Element>

					<Ticker />

					<Footer />

					{this.state._ser > 0 ?
						(<img class="musicOn" src={musicOn} onClick={this.serClose} />) :
						(<img class="musicOn" src={musicOff} onClick={this.ser} />)}

					<img class="stickyGuitar" src={guitar} />

				</div>


			</div>)
	}
}

export default App;
