import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nG1 from '../assets/BLUE NILE.png';
import nG2 from '../assets/ELEGANT BLADES.png';
import nG3 from '../assets/HOOK V.png';
import nG4 from '../assets/IRON CASTER.png';
import nG5 from '../assets/Labyrinth.png';
import nG6 from '../assets/MARINES BULLET.png';
import nG7 from '../assets/nuclear V.png';
import nG8 from '../assets/SAMBA.png';
import nG9 from '../assets/sea wave.png';
import nG10 from '../assets/TRANQUILITY.png';
import nG11 from '../assets/US EAGLE.png';
import nG12 from '../assets/VICTORX.png';
import nG13 from '../assets/VIKING.png';
import nG14 from '../assets/XROLLER.png';

import rG1 from '../assets/crabshell.png';
import rG2 from '../assets/DEVIL EYES.png';
import rG3 from '../assets/FALLEN THUNDERS.png';
import rG4 from '../assets/NORTHERN LIGHT.png';
import rG5 from '../assets/PENTAGRAM.png';
import rG6 from '../assets/STEEL RAZORS.png';
import rG7 from '../assets/STEEL WARRIOR.png';
import rG8 from '../assets/THUNDER.png';
import rG9 from '../assets/WAR HORNS.jpg';
import rG10 from '../assets/X MACHINE.png';


const Stry = () => {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, [])
}
class Guitar extends Component {

  state = {
    _imageOpen: 0
  }

  imageOpen = async event => {
    event.preventDefault();

    this.setState({ _imageOpen: 1 });
  }

  render() {
    return (

      <div class="boxWrapNFT">

        <div class="about2">
          <div class="storyCon2">
            <div class="conT2">Guitars</div>
            <div class="type">
              <div>
                <div class="typeH">In the "Guitarist" NFT Collection, we are proud to introduce a total of 24 AI-generated electric guitars, each with its own unique character. These guitars play a central role in shaping the identity of our NFTs.</div>
              <p></p>
                <div class="typeCon">- Within the 9000 normal NFTs, you'll find 14 of these exceptional electric guitars. Each guitar is designed to enhance the character and style of the guitarist, adding a layer of creativity and individuality to these NFTs.</div>

                <div class="imagesNormal">
                  <div class="imagesNormalDiv">

                    <div>
                      <img src={nG1} />
                      <div class="imgH">BLUE NILE</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG2} />
                      <div class="imgH">ELEGANT BLADES</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG3} />
                      <div class="imgH">HOOK V</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG4} />
                      <div class="imgH">IRON CASTER</div>
                      <p></p>
                    </div>

                    <div>
                      <img src={nG5} />
                      <div class="imgH">Labyrinth</div>
                      <p></p>

                    </div>
                  </div>
                  <div class="imagesNormalDiv">
                    <div>
                      <img src={nG6} />
                      <div class="imgH">MARINES BULLET</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG7} />
                      <div class="imgH">nuclear V</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG8} />
                      <div class="imgH">SAMBA</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG9} />
                      <div class="imgH">sea wave</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG10} />
                      <div class="imgH">TRANQUILITY</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG11} />
                      <div class="imgH">US EAGLE</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={nG12} />
                      <div class="imgH">VICTORX</div>
                      <p></p>

                    </div>                    <div>
                      <img src={nG13} />
                      <div class="imgH">VIKING</div>
                      <p></p>

                    </div>                    <div>
                      <img src={nG14} />
                      <div class="imgH">XROLLER</div>
                      <p></p>

                    </div>
                  </div></div>

                <div class="typeCon">- The remaining 10 AI-generated electric guitars are exclusively shared among the 1000 rare and super rare NFTs. These guitars are crafted to complement the distinctiveness of these rare NFTs, making them even more extraordinary.</div>

                <div class="imagesNormal">
                  <div class="imagesNormalDiv">

                    <div>
                      <img src={rG1} />
                      <div class="imgH">crabshell</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG2} />
                      <div class="imgH">DEVIL EYES</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG3} />
                      <div class="imgH">FALLEN THUNDERS</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG4} />
                      <div class="imgH">NORTHERN LIGHT</div>
                      <p></p>
                    </div>

                    <div>
                      <img src={rG5} />
                      <div class="imgH">PENTAGRAM</div>
                      <p></p>

                    </div>
                  </div>
                  <div class="imagesNormalDiv">
                    <div>
                      <img src={rG6} />
                      <div class="imgH">STEEL RAZORS</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG7} />
                      <div class="imgH">STEEL WARRIOR</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG8} />
                      <div class="imgH">THUNDER</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG9} />
                      <div class="imgH">WAR HORNS</div>
                      <p></p>

                    </div>

                    <div>
                      <img src={rG10} />
                      <div class="imgH">X MACHINE</div>
                      <p></p>

                    </div>
                  </div></div>
              </div>
            </div>
          </div>



        </div>
      </div>
    )
  }
}

export default Guitar;

